import {
  ApiTriggerType,
  ApplicationScope,
  Dimension,
} from "@superblocksteam/shared";
import { call, put, select } from "redux-saga/effects";
import { createNameValidator } from "hooks/store/useEntityNameValidator";
import {
  deleteWidgetProperty,
  updateWidgetProperties,
} from "legacy/actions/controlActions";
import { WidgetAddChild, WidgetDelete } from "legacy/actions/pageActions";
import { deleteWidgets } from "legacy/actions/widgetActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import {
  WidgetWidthModes,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import {
  DataTree,
  DataTreeWidget,
} from "legacy/entities/DataTree/dataTreeFactory";
import { WidgetMetadata } from "legacy/reducers/entityReducers/metaReducer";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getDataTreeWidgetsById } from "legacy/selectors/dataTreeSelectors";
import {
  getFlattenedCanvasWidgets,
  getCurrentApplicationId,
  getCurrentPageId,
} from "legacy/selectors/editorSelectors";
import { getWidget, getWidgets } from "legacy/selectors/entitiesSelector";
import { getDynamicLayoutWidgets } from "legacy/selectors/layoutSelectors";
import { getRoutesList } from "legacy/selectors/routeSelectors";
import { getWidgetsMeta } from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";
import { CanvasWidgetsReduxState } from "legacy/widgets/Factory";
import { getStore } from "store/dynamic";
import renameApplicationEntity from "store/sagas/renameApplicationEntity";
import {
  selectAiApiStatuses,
  selectAiDataTreeChangesById,
} from "store/slices/ai/selectors";
import { createV2ApiSaga } from "store/slices/apisV2";
import { selectAllV2Apis } from "store/slices/apisV2";
import {
  updateV2ApiSaga,
  UpdateV2ApiPayload,
} from "store/slices/apisV2/sagas/updateV2Api";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import { V2ApiPayloadCreators } from "store/slices/apisV2/utils/api-utils";
import { AllFlags, selectFlags } from "store/slices/featureFlags";
import { selectOnlyOrganizationId } from "store/slices/organizations/selectors";
import { AppState } from "store/types";
import { processActionsIntoChanges } from "../processActionsIntoChanges";
import { processAiActions, setAiChanges, setApiStatus } from "../slice";
import { EditMetadata, DiscardedEdit, ClarkActionFuntions } from "../types";

let lastClarkRequestId: string | null = null;
let actionQueue: any[] = [];

export function* processAiActionsSaga(
  action: ReturnType<typeof processAiActions>,
) {
  const appMode: APP_MODE = (yield select(getAppMode)) ?? APP_MODE.EDIT;
  const theme: ReturnType<typeof selectGeneratedTheme> =
    yield select(selectGeneratedTheme);
  const dataTree: DataTree = yield select(
    (state) => state.legacy.evaluations.tree,
  );
  const routes: ReturnType<typeof getRoutesList> = yield select(getRoutesList);
  const state: AppState = yield select((state) => state);
  const featureFlags: Partial<AllFlags> = yield select(selectFlags);
  const existingWidget: WidgetProps | undefined = yield select((state) =>
    getWidget(state, action.payload.existingWidgetId),
  );
  const widgets: Record<string, Partial<WidgetProps>> = yield select(
    getWidgets,
  );
  const dynamicWidgetLayout: ReturnType<typeof getDynamicLayoutWidgets> =
    yield select(getDynamicLayoutWidgets);

  const widgetsRuntime: CanvasWidgetsReduxState = yield select(
    getFlattenedCanvasWidgets,
  );
  const widgetMetaProps: WidgetMetadata = yield select(getWidgetsMeta);
  const evaluatedWidgets: Record<string, DataTreeWidget> = yield select(
    getDataTreeWidgetsById,
  );
  const previousAiWidgets: Record<string, DataTreeWidget> = yield select(
    selectAiDataTreeChangesById,
  );
  const previousApiStatuses: Record<string, "STARTED" | "COMPLETED"> =
    yield select(selectAiApiStatuses) || {};

  const applicationId: string = yield select(getCurrentApplicationId);
  const organizationId: string = yield select(selectOnlyOrganizationId);
  const pageId: string = yield select(getCurrentPageId);
  const apisV2: Record<string, ApiDtoWithPb> = yield select(selectAllV2Apis);
  const getState: () => AppState = () => getStore().getState();

  const validator = createNameValidator(state);

  if (action.payload.clarkRequestId !== lastClarkRequestId) {
    actionQueue = [];
    lastClarkRequestId = action.payload.clarkRequestId;
  }

  const createComponentFn: ClarkActionFuntions["createComponentFn"] = ({
    parentWidgetId,
    widgetType,
    newWidgetId,
    initialProps,
  }) => {
    const createWidgetAction = {
      type: ReduxActionTypes.WIDGET_CREATE,
      payload: {
        widgetId: parentWidgetId,
        type: widgetType,
        newWidgetId,
        widgetName: initialProps.widgetName,
        size: {
          height: initialProps.height as Dimension<WidgetHeightModes>,
          width: initialProps.width as Dimension<WidgetWidthModes>,
        },
        position: {
          top: Dimension.gridUnit(0),
          left: Dimension.gridUnit(0),
        },
        props: {
          ...(initialProps || {}),
        } satisfies Partial<WidgetProps>,
        skipSave: true,
      },
    } satisfies ReduxAction<WidgetAddChild>;

    actionQueue.push(createWidgetAction);
  };

  const renameEntityFn: ClarkActionFuntions["renameEntityFn"] = ({
    entityId,
    oldName,
    newName,
  }) => {
    const renameApplicationEntityAction = renameApplicationEntity.apply({
      entityId,
      oldName,
      newName,
      scope: ApplicationScope.PAGE,
    }).start;
    actionQueue.push(renameApplicationEntityAction);
  };

  const updateComponentFn: ClarkActionFuntions["updateComponentFn"] = ({
    widgetId,
    widgetUpdates,
  }) => {
    const updateWidgetAction = updateWidgetProperties(widgetId, widgetUpdates);
    actionQueue.push(updateWidgetAction);
  };

  const deleteComponentFn: ClarkActionFuntions["deleteComponentFn"] = ({
    widgetId,
  }) => {
    const deleteWidgetAction = {
      type: deleteWidgets.type,
      payload: {
        widgetIds: [widgetId],
      },
    } satisfies ReduxAction<WidgetDelete>;
    actionQueue.push(deleteWidgetAction);
  };

  const deleteComponentPropertiesFn: ClarkActionFuntions["deleteComponentPropertiesFn"] =
    ({ widgetId, propertyPaths }) => {
      const deleteWidgetPropertyAction = deleteWidgetProperty(
        widgetId,
        propertyPaths,
      );
      actionQueue.push(deleteWidgetPropertyAction);
    };

  const createApiFn: ClarkActionFuntions["createApiFn"] = (
    api: ApiDtoWithPb,
  ) => {
    const createApiAction = {
      type: createV2ApiSaga.start.type,
      payload: {
        pageId,
        payload: V2ApiPayloadCreators[ApiTriggerType.UI]({
          apiId: api.id ?? "",
          name: api.name ?? "",
          initialBlocks: api.apiPb.blocks ?? [],
          organizationId,
          applicationId,
          pageId,
        }),
      },
    };

    actionQueue.push(createApiAction);
  };

  const updateApiFn: ClarkActionFuntions["updateApiFn"] = (
    updatedApi: ApiDtoWithPb,
  ) => {
    const updateV2ApiPayload: UpdateV2ApiPayload = {
      apiPb: updatedApi.apiPb,
    };
    const updateApiAction = {
      type: updateV2ApiSaga.start.type,
      payload: updateV2ApiPayload,
    };

    actionQueue.push(updateApiAction);
  };

  if (!existingWidget) {
    return;
  }

  for (const act of action.payload.actions) {
    // mark which apis are in progress
    if (
      act.action === "createApi" ||
      act.action === "editApi" ||
      act.action === "startApi"
    ) {
      const previousStatus = previousApiStatuses?.[act.apiName];
      if (!previousStatus) {
        yield put(setApiStatus({ apiName: act.apiName, status: "STARTED" }));
      }
    }
  }

  const {
    changedKeys,
    widgetRenamesByWidgetId,
    dataTreeChanges,
    metadataByWidgetId,
    discardedEdits,
    apiChanges,
    apiStatuses,
  }: {
    changedKeys: Record<string, string[]>;
    widgetRenamesByWidgetId: Record<string, string>;
    dataTreeChanges: Record<string, Record<string, unknown> | null>;
    metadataByWidgetId: Record<string, EditMetadata>;
    discardedEdits: Record<string, DiscardedEdit[]>;
    apiChanges?: Record<string, ApiDtoWithPb>;
    apiStatuses: Record<string, "STARTED" | "COMPLETED">;
  } = yield call(processActionsIntoChanges, {
    clarkRequestId: action.payload.clarkRequestId,
    actions: action.payload.actions,
    dataTree,
    routes,
    theme,
    nameValidator: validator,
    featureFlags,
    widgets,
    apisV2,
    getState,
    selectedWidgetId: existingWidget.widgetId,
    appMode,
    dynamicWidgetLayout,
    widgetsRuntime,
    widgetMetaProps,
    evaluatedWidgets,
    previousAiWidgets,
    organizationId,
    applicationId,
    pageId,
    createComponentFn,
    updateComponentFn,
    deleteComponentFn,
    deleteComponentPropertiesFn,
    renameEntityFn,
    createApiFn,
    updateApiFn,
  });

  while (actionQueue.length > 0) {
    const action = actionQueue.shift();
    yield put(action);
  }

  yield put(
    setAiChanges({
      changedKeys,
      dataTreeChanges,
      renamesByWidgetId: widgetRenamesByWidgetId,
      discardedEdits,
      metadataByWidgetId,
      apiChanges,
      apiStatuses,
    }),
  );
  for (const act of action.payload.actions) {
    // mark which apis are finished (do this after the actions are processed)
    if (act.action === "completeApi") {
      const previousStatus = previousApiStatuses?.[act.apiName];
      if (previousStatus !== "COMPLETED") {
        yield put(setApiStatus({ apiName: act.apiName, status: "COMPLETED" }));
      }
    }
  }
}
